/**
 * Polyfill za NodeList.forEach() metodu u Internet Exploreru, barem dok ne sleti core-js v3.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/NodeList/forEach#Polyfill source
 * @see https://github.com/zloirock/core-js#iterable-dom-collections zamijeniti s ovim kada izađe core-js v3
 */
if (window.NodeList && !NodeList.prototype.forEach) {
    // eslint-disable-next-line func-names
    NodeList.prototype.forEach = function (callback, thisArg) {
        // eslint-disable-next-line no-param-reassign
        thisArg = thisArg || window;
        for (let i = 0; i < this.length; i += 1) {
            callback.call(thisArg, this[i], i, this);
        }
    };
}
