import '@PROJECT_ROOT/assets/shared/js/infrastructure/nodeListForEachPolyfill';
import 'core-js/es/promise';
import 'core-js/features/number/is-nan';
import 'core-js/features/object/assign';
import 'classlist-polyfill';

/**
 * @see https://developer.mozilla.org/en-US/docs/Web/API/ChildNode/remove#Polyfill
 */
(function removeMethodPolyfill(arr) {
    arr.forEach((item) => {
        if (Object.prototype.hasOwnProperty.call(item, 'remove')) {
            return;
        }
        Object.defineProperty(item, 'remove', {
            configurable: true,
            enumerable: true,
            writable: true,
            value: function remove() {
                if (this.parentNode === null) {
                    return;
                }
                this.parentNode.removeChild(this);
            },
        });
    });
}([Element.prototype, CharacterData.prototype, DocumentType.prototype]));
